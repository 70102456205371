<template>
  <v-container class="fill-height" :class="{ padding__50: $route.name == 'login' }" fluid>
    <v-row align="center" justify="center" class="min__490">
      <v-col :sm="$route.name == 'Login' ? 8 : 12" :md="$route.name == 'Login' ? 8 : 12">
        <v-card class="elevation-12">
          <v-window v-model="step">
            <v-window-item :value="0">
              <v-row class="min__490">
                <v-col cols="12">
                  <v-card-text class="mt-12">
                    <h1 class="text-center display-2 lato">Forgot Password</h1>
                    <v-form @submit.prevent="forgotPassword">
                      <v-text-field v-model="forgot.email" label="Email" name="email" prepend-icon="mdi-email" type="email" />
                      <div class="text-center mt-4">
                        <v-btn class="btn__primary-colour" x-large dark type="submit">Get New Password</v-btn>
                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :value="1">
              <v-row class="min__490">
                <v-col cols="12" md="8">
                  <v-card-text class="mt-12">
                    <h1 class="text-center display-2 lato">Login</h1>
                    <v-form ref="signinFrm" @submit.prevent="loginUser" lazy-validation>
                      <v-text-field v-model="signin.email" label="Email" name="email" prepend-icon="mdi-email" type="email" :rules="[!!signin.email || 'Email is Required']" required />
                      <v-text-field v-model="signin.password" label="Password" name="password" prepend-icon="mdi-lock" type="password" :rules="[!!signin.password || 'Password is Required']" required />
                      <h3 class="text-center mt-3 lato">
                        <a @click="step = 0">Forget your password?</a>
                      </h3>
                      <div class="text-center mt-4">
                        <v-btn class="btn__primary-colour" x-large dark type="submit">SIGN IN</v-btn>
                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
                <v-col cols="12" md="4" class="gradient__background">
                  <v-card-text class="white--text">
                    <h2 class="text-center register__title">Welcome</h2>
                    <h4 class="text-center register__text">
                      Enter your personal details and gain access to legal documentation.
                    </h4>
                  </v-card-text>
                  <div class="text-center">
                    <v-btn outlined x-large dark @click="step++">Register</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :value="2">
              <v-row class="min__490">
                <v-col cols="12" md="4" class="gradient__background">
                  <v-card-text class="white--text">
                    <h1 class="text-center register__title">Welcome back!</h1>
                    <h5 class="text-center register__text">
                      Already have an account? Please login with your personal account
                    </h5>
                  </v-card-text>
                  <div class="text-center">
                    <v-btn outlined x-large dark @click="step--">SIGN IN</v-btn>
                  </div>
                </v-col>
                <v-col cols="12" md="8">
                  <h1 class="text-center display-2 lato">Register</h1>
                  <v-form ref="registerFrm" @submit.prevent="registerUser" lazy-validation>
                    <v-row>
                      <v-col>
                        <v-text-field v-model="register.firstName" label="First Name" name="name" prepend-icon="mdi-account-details" :rules="firstNameRules" required></v-text-field>
                        <v-text-field v-model="register.lastName" label="Last Name" name="last_name" prepend-icon="mdi-account-details" :rules="lastNameRules" required></v-text-field>
                        <v-text-field v-model="register.email" label="Email" name="email" prepend-icon="mdi-email" type="email" :rules="emailRules" required />
                        <v-text-field v-model="register.telNumber" label="Mobile" name="tel_number" prepend-icon="mdi-email" :rules="telNumberRules" required />
                        <v-text-field v-model="register.password" label="Password" name="password" prepend-icon="mdi-lock" type="password" :rules="passwordRules" required />
                        <v-text-field v-model="register.confirm" label="Confirm" name="confirm" prepend-icon="mdi-lock" type="password" :rules="[!!register.confirm || 'Password Confirmation required', (register.confirm && register.confirm == register.password) || 'Passwords do not match']" required />
                      </v-col>
                      <v-col>
                        <v-text-field v-model="register.location.addy1" label="Address 1" name="addy1" prepend-icon="mdi-map-marker-outline" :rules="addy1Rules" required></v-text-field>
                        <v-text-field v-model="register.location.addy2" label="Address 2" name="addy2" prepend-icon="mdi-map-marker-outline"></v-text-field>
                        <v-text-field v-model="register.location.suburb" label="Suburb" name="suburb" prepend-icon="mdi-map-marker-outline"></v-text-field>
                        <v-text-field v-model="register.location.city" label="City" name="city" prepend-icon="mdi-map-marker-outline" :rules="cityRules" required></v-text-field>
                        <v-text-field v-model="register.location.country" label="Country" name="country" prepend-icon="mdi-map-marker-outline"></v-text-field>
                        <v-text-field v-model="register.location.postcode" label="Postcode" name="postcode" prepend-icon="mdi-map-marker-outline" :rules="postcodeRules" required></v-text-field>
                      </v-col>
                    </v-row>
                    <h3 class="text-center mt-3 lato">Forget your password?</h3>
                    <div class="text-center mt-4">
                      <v-btn class="btn__primary-colour" x-large dark type="submit" :disabled="!valid">CREATE ACCOUNT</v-btn>
                    </div>
                  </v-form>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";
export default {
  name: "LoginRegister",
  data: () => ({
    ping: "",

    step: 1,

    register: new User(),

    firstNameRules: [(v) => !!v || "First name is required"],

    lastNameRules: [(v) => !!v || "Last name is required"],

    emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],

    telNumberRules: [(v) => !!v || "Mobile Number is required"],

    passwordRules: [(v) => !!v || "Password is required", (v) => (v && v.length >= 6) || "minimum 6 characters"],

    addy1Rules: [(v) => !!v || "Address 1 is required"],

    cityRules: [(v) => !!v || "City is required"],

    postcodeRules: [(v) => !!v || "postcode is required"],

    signin: {
      email: "",
      password: "",
    },

    forgot: {
      email: "",
    },

    successMsg: "",
    errorMsg: "",

    valid: true,
  }),
  apollo: {
    ping: {
      query: gql`
        query {
          ping
        }
      `,
      fetchPolicy: "network-only",
      result(data, key) {
        console.log(data, key);
      },
    },
  },
  methods: {
    async forgotPassword() {
      const self = this;
      try {
        await this.$apollo
          .mutate({
            mutation: gql`
              mutation getResetPasswordLink($email: String!) {
                getResetPasswordLink(email: $email)
              }
            `,
            variables: {
              email: self.forgot.email,
            },
          })
          .then(() => {
            self.$swal({
              title: "Success",
              text: "Please check your email for reset password link.",
              icon: "success",
              confirmButtonColor: "#c79477",
            });
          });
      } catch (e) {
        console.log(`Forgot Password fetch link failed: ${JSON.stringify(e)}`);
        self.errorMsg = `${e.message}`;
      }
    },

    async loginUser() {
      const self = this;
      if (!self.$refs.signinFrm.validate()) {
        self.$swal({
          title: "Error",
          text: "Validation failed",
          icon: "error",
          confirmButtonColor: "#c79477",
        });
        return;
      }
      try {
        const userData = await this.$apollo.mutate({
          mutation: gql`
            mutation($email: String!, $password: String!) {
              login(email: $email, password: $password) {
                user {
                  id
                  email
                  role
                }
                token
              }
            }
          `,
          variables: {
            email: this.signin.email.toLowerCase(),
            password: this.signin.password,
          },
        });

        if (userData) {
          this.$token = userData?.data?.login?.token;
          this.$role = userData?.data?.login?.user?.role;
          localStorage.setItem("token", this.$token);
          localStorage.setItem("role", userData?.data?.login?.user?.role);

          if (this.$route.name == "Checkout") {
            this.$eventHub.$emit("logged-in");
          } else if (this.$route.name == "Products" || this.$route.name == "Products Details") {
            this.$eventHub.$emit("logged-in");
            this.$emit("addToCart");
          } else {
            if (this.$role == "user") this.$router.push("/user/purchases");
            if (this.$role == "admin") this.$router.push("/admin/orders");
          }
        }
      } catch (e) {
        console.log(`Login Failed ${JSON.stringify(e)}`);
        self.errorMsg = `${e.message}`;
      }
    },

    async registerUser() {
      const self = this;
      if (!self.$refs.registerFrm.validate()) {
        self.$swal({
          title: "Error",
          text: "Validation failed",
          icon: "error",
          confirmButtonColor: "#c79477",
        });
        return;
      }
      try {
        const userData = await this.$apollo.mutate({
          mutation: gql`
            mutation createUser($user: UserCreateInput!) {
              createUser(user: $user) {
                id
              }
            }
          `,
          variables: {
            user: {
              firstName: this.register.firstName,
              lastName: this.register.lastName,
              email: this.register.email.toLowerCase(),
              telNumber: this.register.telNumber,
              password: this.register.password,
              fullAddress: this.register.fullAddress,
              role: "user",
            },
          },
        });

        if (userData) {
          self.$token = userData.data.createUser.token;
          localStorage.setItem("token", self.$token);
          localStorage.setItem("role", "user");
          this.step--;
          this.$swal({
            title: "Success",
            text: "You have successfully registered, please login",
            icon: "success",
            confirmButtonColor: "#c79477",
          });
        }
      } catch (e) {
        console.log(`Login Failed ${JSON.stringify(e)}`);
        self.errorMsg = `${e.message}`;
        self.$swal({
          title: "Error",
          text: e.message,
          icon: "error",
          confirmButtonColor: "#c79477",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/scss/_variables.scss";

.gradient__background {
  background: $gradient-background;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.padding__50 {
  padding: 50px 15px;
}

.min__490 {
  min-height: 490px;
}

.register {
  &__title {
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 35px;
    font-family: $lato;
    line-height: 47px;
  }
  &__text {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 400;
    font-family: $lato;
  }
}
</style>
