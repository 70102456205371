<template>
  <div>
    <section-banner v-show="$route.name !== 'Checkout'" :model="section"></section-banner>
    <login-register />
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";
import SectionBanner from "@/components/Frontend/SectionBanner";
import LoginRegister from "@/components/Frontend/LoginRegister.vue";
export default {
  name: "Login",
  components: {
    SectionBanner,
    LoginRegister,
  },
  data: () => ({
    ping: "",

    step: 1,

    register: new User(),

    signin: {
      email: "",
      password: "",
    },

    successMsg: "",
    errorMsg: "",

    section: {
      BannerSmall: true,
      Title: "Register & Login",
      Subtitle: "Your portal of legal advice and services",
      Description: "Create and account or login to your existing account to access your DIY Documents and to gain access to boutique services.",
      img: require("@/assets/img/background.jpg"),
    },
  }),
  apollo: {
    ping: {
      query: gql`
        query {
          ping
        }
      `,
      // pollInterval: 2000,
      fetchPolicy: "network-only",
      result(data, key) {
        console.log(data, key);
      },
    },
  },
  methods: {
    async loginUser() {
      try {
        const userData = await this.$apollo.mutate({
          mutation: gql`
            mutation($email: String!, $password: String!) {
              login(email: $email, password: $password) {
                user {
                  id
                  email
                  role
                }
                token
              }
            }
          `,
          variables: {
            email: this.signin.email,
            password: this.signin.password,
          },
        });

        if (userData) {
          this.$token = userData?.data?.login?.token;
          this.$role = userData?.data?.login?.user?.role;
          localStorage.setItem("token", this.$token);
          localStorage.setItem("role", userData?.data?.login?.user?.role);
          if (this.$route.name == "Checkout") {
            this.$eventHub.$emit("logged-in");
          } else {
            if (this.$role == "user") this.$router.push("/user/purchases");
            if (this.$role == "admin") this.$router.push("/admin/orders");
          }
        }
      } catch (e) {
        console.log(`Login Failed ${JSON.stringify(e)}`);
        self.errorMsg = `${e.message}`;
      }
    },

    async registerUser() {
      try {
        const self = this;
        const userData = await this.$apollo.mutate({
          mutation: gql`
            mutation createUser($user: UserCreateInput!) {
              createUser(user: $user) {
                id
              }
            }
          `,
          variables: {
            user: {
              firstName: this.register.firstName,
              lastName: this.register.lastName,
              email: this.register.email,
              telNumber: this.register.telNumber,
              password: this.register.password,
              fullAddress: this.register.fullAddress,
              role: "user",
            },
          },
        });

        if (userData) {
          self.$token = userData.data.createUser.token;
          localStorage.setItem("token", self.$token);
          localStorage.setItem("role", "user");
          this.step--;
          this.$swal({
            title: "Success",
            text: "You have successfully registered, please login.",
            icon: "success",
            confirmButtonColor: "#c79477",
          });
        }
      } catch (e) {
        console.log(`Login Failed ${JSON.stringify(e)}`);
        self.errorMsg = `${e.message}`;
        self.$swal({
          title: "Error",
          text: e.message,
          icon: "error",
          confirmButtonColor: "#c79477",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/scss/_variables.scss";

.gradient__background {
  background: $gradient-background;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.padding__50 {
  padding: 50px 15px;
}

.min__490 {
  min-height: 490px;
}

.register {
  &__title {
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 35px;
    font-family: $lato;
    line-height: 47px;
  }
  &__text {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 400;
    font-family: $lato;
  }
}
</style>
